import { __assign, __awaiter, __decorate, __extends, __generator, __read, __spread } from "tslib";
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { QBtn, QBtnGroup, QCard, QCardSection, QForm, QIcon, QItem, QItemLabel, QItemSection, QRadio, QSelect, QSeparator, QToggle, QTooltip } from 'quasar';
import Tooltip from '../../components/tooltip/Tooltip.vue';
import useGetCalculatorTypes from '../../utils/calculatorTypes';
import useGetDocuments from '../../utils/documents';
import useLabels from '../../utils/labels';
import { createAPIKey } from '../../utils/serviceProxy';
import labels from './labels';
import TcAddress from './TcAddress.vue';
import TcUsage from './TcUsage.vue';
import { createUsageValidator, createZipCodeValidator } from './validation';
var TariffCalculatorInput = /** @class */ (function (_super) {
    __extends(TariffCalculatorInput, _super);
    function TariffCalculatorInput() {
        var _a;
        var _this = _super.apply(this, __spread(arguments)) || this;
        _this.THG_BASE_URL = 'https://view.equota.de/view/034c6da3-c1c2-4fe3-bae4-267597e6644c';
        // Data
        _this.zipCode = (_a = _this.plz) !== null && _a !== void 0 ? _a : '';
        _this.city = '';
        _this.street = null;
        _this.houseNumber = null;
        _this.onlyEco = false;
        _this.usageHT = 3200;
        _this.usageNT = 1000;
        _this.netzbetreiber = '';
        _this.grundversorger = '';
        _this.useTwoTariffs = false;
        _this.tariftyp = '1,2';
        _this.customerType = 0;
        _this.apikey = '';
        _this.usageHTGas = 20000;
        _this.tariffCalcTypeId = '1';
        _this.isLocationLoading = false;
        _this.locationInfo = { zipCode: '', unique: false };
        _this.isElectricityEnabled = false;
        _this.isGasEnabled = false;
        _this.isHeizstromEnabled = false;
        _this.isAutostromEnabled = false;
        _this.isTelcoEnabled = false;
        _this.isTHGEnabled = false;
        _this.labelsReady = false;
        _this.privacyUrl = '';
        return _this;
    }
    TariffCalculatorInput.prototype.created = function () {
        var _a, _b, _c, _d, _e, _f;
        this.getProxyLabels();
        this.usageHT = (_a = this.initialValue.usageHT) !== null && _a !== void 0 ? _a : this.usageHT;
        this.usageHTGas = (_b = this.initialValue.usageHTGas) !== null && _b !== void 0 ? _b : this.usageHTGas;
        this.usageNT = (_c = this.initialValue.usageNT) !== null && _c !== void 0 ? _c : this.usageNT;
        this.zipCode = (_d = this.initialValue.zipCode) !== null && _d !== void 0 ? _d : this.plz;
        this.city = this.initialValue.city ? this.initialValue.city.trim() : this.city.trim();
        this.onlyEco = (_e = this.initialValue.onlyEco) !== null && _e !== void 0 ? _e : this.onlyEco;
        this.useTwoTariffs = (_f = this.initialValue.tariff === 'Zweitarif') !== null && _f !== void 0 ? _f : this.useTwoTariffs;
        this.tariffCalcTypeId = this.tariffCalculatorTypeId;
        this.syncCalculatorTypeId = this.tariffCalculatorTypeId;
        if (this.oeko === 1)
            this.onlyEco = true;
        if (this.kundenart)
            this.customerType = Number(this.kundenart);
        console.log('this.tariffCalculatorTypeId A', this.tariffCalculatorTypeId, this.syncCalculatorTypeId);
        this.getCalculatorTypeIds();
        console.log('this.tariffCalculatorTypeId B', this.tariffCalculatorTypeId, this.syncCalculatorTypeId);
        if (this.loadAutomaticResult) {
            this.setAutomaticLocation();
        }
        else
            this.setCalulatorIDs();
    };
    TariffCalculatorInput.prototype.setAutomaticLocation = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        console.log('setAutomaticLocation', this.extern_ort);
                        if (this.plz)
                            this.zipCode = this.plz;
                        if (this.extern_ort)
                            this.city = this.extern_ort;
                        if (this.extern_strasse)
                            this.street = this.extern_strasse;
                        if (this.extern_hausnummer)
                            this.houseNumber = this.extern_hausnummer;
                        return [4 /*yield*/, this.updateLocationInfo(this.locationFilter, this.zipCode, this.extern_ort, this.extern_strasse, this.extern_hausnummer, this.extern_AdressID)];
                    case 1:
                        _a.sent();
                        if (this.isLocationUnique && this.city.length > 0 && this.extern_AdressID < 1 && this.tariffCalcTypeId !== '7') {
                            this.onSubmitForm();
                        }
                        else {
                            //  throw new Error(`No SubmitForm ${this.isLocationUnique} ${this.city.length}`);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculatorInput.prototype.setCalulatorIDs = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
        switch (this.tariffCalcTypeId) {
            default:
                this.syncCtx = 'electricity';
                if (!this.extern_ort)
                    this.city = '';
                if (!this.extern_ort) {
                    if (((_c = (_b = (_a = this.customLabelsProxyInput) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.functionsinputPage) === null || _c === void 0 ? void 0 : _c.defaultZipCode) && ((_d = this.plz) === null || _d === void 0 ? void 0 : _d.length) === 0)
                        this.zipCode = (_g = (_f = (_e = this.customLabelsProxyInput) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.functionsinputPage) === null || _g === void 0 ? void 0 : _g.defaultZipCode;
                }
                break;
            case '1':
                this.syncCtx = 'electricity';
                if (!this.extern_ort)
                    this.city = '';
                if (!this.extern_ort) {
                    if (((_k = (_j = (_h = this.customLabelsProxyInput) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.functionsinputPage) === null || _k === void 0 ? void 0 : _k.defaultZipCode) && ((_l = this.plz) === null || _l === void 0 ? void 0 : _l.length) === 0)
                        this.zipCode = (_p = (_o = (_m = this.customLabelsProxyInput) === null || _m === void 0 ? void 0 : _m.data) === null || _o === void 0 ? void 0 : _o.functionsinputPage) === null || _p === void 0 ? void 0 : _p.defaultZipCode;
                }
                break;
            case '2':
                this.syncCtx = 'gas';
                break;
            case '3':
                this.syncCtx = 'heating';
                if (!this.extern_ort)
                    this.city = '';
                if (!this.extern_ort) {
                    if (((_s = (_r = (_q = this.customLabelsProxyInput) === null || _q === void 0 ? void 0 : _q.data) === null || _r === void 0 ? void 0 : _r.functionsinputPage) === null || _s === void 0 ? void 0 : _s.defaultZipCode) && ((_t = this.plz) === null || _t === void 0 ? void 0 : _t.length) === 0)
                        this.zipCode = (_w = (_v = (_u = this.customLabelsProxyInput) === null || _u === void 0 ? void 0 : _u.data) === null || _v === void 0 ? void 0 : _v.functionsinputPage) === null || _w === void 0 ? void 0 : _w.defaultZipCode;
                }
                this.tariftyp = '3';
                break;
            case '4':
                this.syncCtx = 'autostrom';
                if (!this.extern_ort)
                    this.city = '';
                if (!this.extern_ort) {
                    if (((_z = (_y = (_x = this.customLabelsProxyInput) === null || _x === void 0 ? void 0 : _x.data) === null || _y === void 0 ? void 0 : _y.functionsinputPage) === null || _z === void 0 ? void 0 : _z.defaultZipCode) && ((_0 = this.plz) === null || _0 === void 0 ? void 0 : _0.length) === 0)
                        this.zipCode = (_3 = (_2 = (_1 = this.customLabelsProxyInput) === null || _1 === void 0 ? void 0 : _1.data) === null || _2 === void 0 ? void 0 : _2.functionsinputPage) === null || _3 === void 0 ? void 0 : _3.defaultZipCode;
                }
                this.tariftyp = '1';
                break;
            case '7':
                this.syncCtx = 'telco';
                if (!this.extern_ort) {
                    if (((_6 = (_5 = (_4 = this.customLabelsProxyInput) === null || _4 === void 0 ? void 0 : _4.data) === null || _5 === void 0 ? void 0 : _5.functionsinputPage) === null || _6 === void 0 ? void 0 : _6.defaultZipCode) && ((_7 = this.plz) === null || _7 === void 0 ? void 0 : _7.length) === 0)
                        this.zipCode = (_10 = (_9 = (_8 = this.customLabelsProxyInput) === null || _8 === void 0 ? void 0 : _8.data) === null || _9 === void 0 ? void 0 : _9.functionsinputPage) === null || _10 === void 0 ? void 0 : _10.defaultZipCode;
                }
                if (!this.extern_ort)
                    this.city = '';
                break;
            case '8':
                this.syncCtx = 'thg';
                break;
        }
    };
    TariffCalculatorInput.prototype.setSyncCalculatorTypeId = function () {
        switch (this.syncCalculatorTypeId) {
            default:
                this.syncCtx = 'electricity';
                break;
            case '1':
                this.syncCtx = 'electricity';
                break;
            case '2':
                this.syncCtx = 'gas';
                break;
            case '3':
                this.syncCtx = 'heating';
                this.tariftyp = '3';
                break;
            case '4':
                this.syncCtx = 'autostrom';
                this.tariftyp = '1';
                break;
            case '7':
                this.syncCtx = 'telco';
                // if (this.plz && this.zipCode === '') this.zipCode = this.plz; // 65428
                //    this.zipCode = '65428';
                break;
            case '8':
                this.syncCtx = 'thg';
                break;
        }
    };
    TariffCalculatorInput.prototype.getCalculatorTypeIds = function () {
        var _a, _b, _c, _d, _e, _f, _g;
        return __awaiter(this, void 0, void 0, function () {
            var getCalculatorTypes, res, _h, e_1;
            return __generator(this, function (_j) {
                switch (_j.label) {
                    case 0:
                        getCalculatorTypes = useGetCalculatorTypes().getCalculatorTypes;
                        this.labelsReady = false;
                        _j.label = 1;
                    case 1:
                        _j.trys.push([1, 8, , 9]);
                        return [4 /*yield*/, getCalculatorTypes()];
                    case 2:
                        res = (_j.sent());
                        if (!res.data) return [3 /*break*/, 6];
                        this.isElectricityEnabled = res.data.calculatorTypeIDs.includes('1');
                        //   if(this.isElectricityEnabled)await this.checkDefault('1');
                        this.isGasEnabled = (_a = res.data) === null || _a === void 0 ? void 0 : _a.calculatorTypeIDs.includes('2'); // await this.checkDefault('2');
                        this.isHeizstromEnabled = (_b = res.data) === null || _b === void 0 ? void 0 : _b.calculatorTypeIDs.includes('3'); // await this.checkDefault('3');
                        this.isAutostromEnabled = (_c = res.data) === null || _c === void 0 ? void 0 : _c.calculatorTypeIDs.includes('4'); // await this.checkDefault('4');
                        this.isTelcoEnabled = (_d = res.data) === null || _d === void 0 ? void 0 : _d.calculatorTypeIDs.includes('7'); // await this.checkDefault('7');
                        this.isTHGEnabled = (_e = res.data) === null || _e === void 0 ? void 0 : _e.calculatorTypeIDs.includes('8');
                        this.syncCalculatorIDsConfig = res;
                        if (!this.isTelcoEnabled) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.checkDefault((_f = res.data) === null || _f === void 0 ? void 0 : _f.calculatorTypeIDs, '7', 'telco')];
                    case 3:
                        _j.sent();
                        _j.label = 4;
                    case 4:
                        if (!this.isTHGEnabled) return [3 /*break*/, 6];
                        return [4 /*yield*/, this.checkDefault((_g = res.data) === null || _g === void 0 ? void 0 : _g.calculatorTypeIDs, '8', 'thg')];
                    case 5:
                        _j.sent();
                        _j.label = 6;
                    case 6:
                        _h = this;
                        return [4 /*yield*/, this.AGB()];
                    case 7:
                        _h.privacyUrl = _j.sent();
                        return [3 /*break*/, 9];
                    case 8:
                        e_1 = _j.sent();
                        console.error(e_1.message);
                        throw e_1;
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculatorInput.prototype.checkDefault = function (resCalculatorTypeIDs, tid, context) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(resCalculatorTypeIDs.length < 2 && resCalculatorTypeIDs.includes(tid))) return [3 /*break*/, 2];
                        this.tariffCalculatorTypeId = tid;
                        this.tariffCalcTypeId = tid;
                        switch (tid) {
                            case '1':
                                this.syncCalculatorTypeId = '1';
                                break;
                            case '2':
                                this.syncCalculatorTypeId = '2';
                                break;
                            case '3':
                                this.syncCalculatorTypeId = '3';
                                break;
                            case '4':
                                this.syncCalculatorTypeId = '4';
                                break;
                            case '7':
                                this.handleTelcoContext();
                                break;
                            case '8':
                                this.syncCalculatorTypeId = '8';
                                break;
                            default:
                                this.syncCalculatorTypeId = '8';
                                break;
                        }
                        this.syncCtx = context;
                        return [4 /*yield*/, this.getProxyLabels()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculatorInput.prototype.getProxyLabels = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey, getExternalLabels, _a, e_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _b.sent();
                        getExternalLabels = useLabels({ apikey: apikey }).getExternalLabels;
                        this.syncCtx = 'undefined';
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, 5, 6]);
                        _a = this;
                        return [4 /*yield*/, getExternalLabels(this.tariffCalcTypeId, undefined, 'inputpage')];
                    case 3:
                        _a.customLabelsProxyInput = (_b.sent()).data;
                        return [3 /*break*/, 6];
                    case 4:
                        e_2 = _b.sent();
                        console.error(e_2.message);
                        throw e_2;
                    case 5:
                        this.setCalulatorIDs();
                        this.labelsReady = true;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculatorInput.prototype.showCancelationFormButton = function () {
        var _a, _b, _c;
        return ((_c = (_b = (_a = this.customLabelsProxyInput) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.functionsinputPage) === null || _c === void 0 ? void 0 : _c.showCancelationForm) === true;
    };
    TariffCalculatorInput.prototype.AGB = function () {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var apikey, docs, res, e_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _b.sent();
                        _b.label = 2;
                    case 2:
                        _b.trys.push([2, 4, , 5]);
                        docs = useGetDocuments({ apikey: apikey });
                        return [4 /*yield*/, docs.getDocuments(parseFloat(this.tariffCalcTypeId), undefined)];
                    case 3:
                        res = _b.sent();
                        return [2 /*return*/, (_a = res === null || res === void 0 ? void 0 : res.data.privacyUrl) !== null && _a !== void 0 ? _a : ''];
                    case 4:
                        e_3 = _b.sent();
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/, ''];
                }
            });
        });
    };
    Object.defineProperty(TariffCalculatorInput.prototype, "labels", {
        // Computed
        get: function () {
            var _a, _b;
            return __assign(__assign(__assign({}, labels), this.customLabels), (_b = (_a = this.customLabelsProxyInput) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.inputPage);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "isShow", {
        get: function () {
            return !this.teaser;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "isElectricityContext", {
        get: function () {
            return this.syncCtx === 'electricity';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "isHeizstromContext", {
        get: function () {
            return this.syncCtx === 'heating';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "isGasContext", {
        get: function () {
            return this.syncCtx === 'gas';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "isTHGContext", {
        get: function () {
            return this.syncCtx === 'thg';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "isAutostromContext", {
        get: function () {
            return this.syncCtx === 'autostrom';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "isCalcElectricityContext", {
        get: function () {
            return this.tariffCalcTypeId === '1';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "isCalcGasContext", {
        get: function () {
            return this.tariffCalcTypeId === '2';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "isCalcHeizstromContext", {
        get: function () {
            return this.tariffCalcTypeId === '3';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "isCalcAutostromContext", {
        get: function () {
            return this.tariffCalcTypeId === '4';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "isCalcTelcoContext", {
        get: function () {
            return this.tariffCalcTypeId === '7';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "isCalcTHGContext", {
        get: function () {
            return this.tariffCalcTypeId === '8';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "titleContext", {
        get: function () {
            var was;
            switch (this.tariffCalcTypeId) {
                case '1':
                    was = this.labels.electricity;
                    break;
                case '2':
                    was = this.labels.gas;
                    break;
                case '3':
                    was = this.labels.heizstrom;
                    break;
                case '4':
                    was = this.labels.autostrom;
                    break;
                case '7':
                    was = '';
                    break;
                case '8':
                    was = '';
                    break;
                default:
                    was = this.labels.electricity;
                    break;
            }
            return was;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "usageLabel", {
        get: function () {
            return this.useTwoTariffs ? this.labels.labelHT : this.labels.labelHT.replace(/ \(HT\)/g, '');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "formValue", {
        get: function () {
            var _a = this, zipCode = _a.zipCode, city = _a.city, street = _a.street, houseNumber = _a.houseNumber, usageHT = _a.usageHT, usageHTGas = _a.usageHTGas, usageNT = _a.usageNT, onlyEco = _a.onlyEco, useTwoTariffs = _a.useTwoTariffs, customerType = _a.customerType, netzbetreiber = _a.netzbetreiber, grundversorger = _a.grundversorger, versorgerIDs = _a.versorgerIDs, bonus = _a.bonus, markenIDs = _a.markenIDs, tarifRootIDs = _a.tarifRootIDs, tarifRootIDsMindestfilter = _a.tarifRootIDsMindestfilter, zaehlerart = _a.zaehlerart, baiextendedWhiteList = _a.baiextendedWhiteList, stichtag = _a.stichtag, tarifcode = _a.tarifcode, handlingTarife = _a.handlingTarife, tariftyp = _a.tariftyp;
            var commonFields = __assign(__assign(__assign({ zipCode: zipCode,
                city: city }, (street ? { street: street } : {})), (houseNumber ? { houseNumber: houseNumber } : {})), { usageHT: this.isElectricityContext || this.isHeizstromContext || this.isAutostromContext ? usageHT : usageHTGas, onlyEco: onlyEco,
                customerType: customerType,
                netzbetreiber: netzbetreiber,
                grundversorger: grundversorger, medium: this.syncCtx, tariffCalculatorTypeId: this.tariffCalcTypeId, versorgerIDs: versorgerIDs,
                bonus: bonus,
                markenIDs: markenIDs,
                tarifRootIDs: tarifRootIDs,
                tarifRootIDsMindestfilter: tarifRootIDsMindestfilter,
                zaehlerart: zaehlerart,
                baiextendedWhiteList: baiextendedWhiteList,
                stichtag: stichtag,
                tarifcode: tarifcode,
                handlingTarife: handlingTarife,
                tariftyp: tariftyp, authToken: this.authToken, authTokenRefresh: this.authTokenRefresh, externAddressID: this.extern_AdressID, authTokenUrl: this.authTokenUrl });
            if (useTwoTariffs) {
                return __assign(__assign({}, commonFields), { usageNT: usageNT, tariff: 'Zweitarif', externLocationId: '' });
            }
            return __assign(__assign({}, commonFields), { tariff: 'Eintarif', externLocationId: '' });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "usageHTError", {
        get: function () {
            return createUsageValidator(1, 999999, this.labels.usageInvalidMessage)(this.usageHT);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "usageNTError", {
        get: function () {
            return createUsageValidator(1, 999999, this.labels.usageInvalidMessage)(this.usageNT);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "zipCodeError", {
        get: function () {
            return createZipCodeValidator(this.labels.zipCodeInvalidMessage)(this.zipCode);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffCalculatorInput.prototype, "isLocationUnique", {
        get: function () {
            return this.locationInfo.unique;
        },
        enumerable: false,
        configurable: true
    });
    TariffCalculatorInput.prototype.handleElectricityContext = function () {
        if (!this.isElectricityEnabled)
            return;
        this.syncCtx = 'undefined';
        this.syncCalculatorTypeId = '1';
        this.getProxyLabels();
        this.syncCtx = 'electricity';
        this.tariffCalcTypeId = '1';
        if (!this.extern_ort) {
            this.zipCode = '';
            this.city = '';
        }
        this.forceLocationUpdate();
    };
    TariffCalculatorInput.prototype.handleHeizstromContext = function () {
        if (!this.isHeizstromEnabled)
            return;
        this.syncCtx = 'undefined';
        this.syncCalculatorTypeId = '3';
        this.getProxyLabels();
        this.syncCtx = 'heating';
        this.tariffCalcTypeId = '3';
        this.tariftyp = '3';
        if (!this.extern_ort) {
            this.zipCode = '';
            this.city = '';
        }
        this.forceLocationUpdate();
    };
    TariffCalculatorInput.prototype.handleAutostromContext = function () {
        if (!this.isAutostromEnabled)
            return;
        this.syncCtx = 'undefined';
        this.syncCalculatorTypeId = '4';
        this.getProxyLabels();
        this.syncCtx = 'autostrom';
        this.tariffCalcTypeId = '4';
        this.tariftyp = '1';
        if (!this.extern_ort) {
            this.zipCode = '';
            this.city = '';
        }
        this.forceLocationUpdate();
    };
    TariffCalculatorInput.prototype.handleTelcoContext = function () {
        var _a;
        if (!this.isTelcoEnabled)
            return;
        if (((_a = this.plz) === null || _a === void 0 ? void 0 : _a.length) === 0 && !this.extern_ort) {
            this.zipCode = '';
            this.city = '';
        }
        this.syncCtx = 'undefined';
        this.syncCalculatorTypeId = '7';
        this.tariffCalcTypeId = '7';
        this.getProxyLabels();
        this.syncCtx = 'telco';
        this.tariftyp = '1';
        this.forceLocationUpdate();
    };
    TariffCalculatorInput.prototype.handleTHGContext = function () {
        if (!this.isTHGEnabled)
            return;
        this.syncCtx = 'undefined';
        this.syncCalculatorTypeId = '8';
        this.getProxyLabels();
        this.syncCtx = 'thg';
        this.tariffCalcTypeId = '8';
        this.forceLocationUpdate();
    };
    TariffCalculatorInput.prototype.handleGasContext = function () {
        if (!this.isGasEnabled)
            return;
        this.syncCtx = 'undefined';
        this.syncCalculatorTypeId = '2';
        this.getProxyLabels();
        this.syncCtx = 'gas';
        this.useTwoTariffs = false;
        this.tariffCalcTypeId = '2';
        this.forceLocationUpdate();
    };
    TariffCalculatorInput.prototype.forceLocationUpdate = function () {
        // if (this.extern_ort) this.city = this.city.concat(' ');
    };
    TariffCalculatorInput.prototype.handleUsageHTGas = function (usage) {
        this.usageHTGas = parseInt(usage, 10);
        return this.usageHTGas;
    };
    Object.defineProperty(TariffCalculatorInput.prototype, "isFormValid", {
        get: function () {
            var _a;
            if (this.teaser) {
                if (this.locationInfo.cityOptions) {
                    return !this.zipCodeError && !this.usageHTError && ((_a = this.locationInfo.cityOptions) === null || _a === void 0 ? void 0 : _a.length) > 0;
                }
            }
            console.log(' this.isLocationUnique', this.isLocationUnique);
            if (this.isCalcTelcoContext) {
                return !this.zipCodeError && !this.usageHTError && (this.useTwoTariffs ? !this.usageNTError : true) && this.isLocationUnique && this.houseNumber !== null;
            }
            return !this.zipCodeError && !this.usageHTError && (this.useTwoTariffs ? !this.usageNTError : true) && this.isLocationUnique && this.city;
        },
        enumerable: false,
        configurable: true
    });
    TariffCalculatorInput.prototype.onZipCodeChange = function (zipCode) {
        this.city = '';
        this.street = null;
        this.houseNumber = null;
        if (!this.zipCodeError) {
            this.updateLocationInfo(this.locationFilter, zipCode);
        }
    };
    TariffCalculatorInput.prototype.onCityChange = function (city) {
        if (this.loadAutomaticResult && this.isLocationUnique)
            return;
        this.street = null;
        this.houseNumber = null;
        if (city) {
            this.updateLocationInfo(this.locationFilter, this.zipCode, city);
        }
        if (this.loadAutomaticResult) {
            if (!this.zipCodeError && !this.usageHTError && (this.useTwoTariffs ? !this.usageNTError : true) && this.city.length > 0 && this.isLocationUnique)
                this.onSubmitForm();
        }
    };
    TariffCalculatorInput.prototype.onStreetChange = function (street) {
        if (this.loadAutomaticResult && this.isLocationUnique)
            return;
        this.houseNumber = null;
        if (street) {
            this.updateLocationInfo(this.locationFilter, this.zipCode, this.city, street);
        }
        if (this.loadAutomaticResult && this.isLocationUnique && !this.locationInfo.invalid) {
            if (!this.zipCodeError && !this.usageHTError && (this.useTwoTariffs ? !this.usageNTError : true) && ((this.city.length > 0 && street.length > 0) || this.isLocationUnique))
                this.onSubmitForm();
        }
    };
    TariffCalculatorInput.prototype.onHouseNumberChange = function (houseNumber) {
        var _a;
        if (this.loadAutomaticResult && this.isLocationUnique)
            return;
        if (houseNumber) {
            this.updateLocationInfo(this.locationFilter, this.zipCode, this.city, (_a = this.street) !== null && _a !== void 0 ? _a : undefined, houseNumber);
        }
    };
    TariffCalculatorInput.prototype.updateLocationInfo = function (locationFilter, zipCode, city, street, houseNumber, addressID) {
        return __awaiter(this, void 0, void 0, function () {
            var locationInfo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLocationLoading = true;
                        return [4 /*yield*/, this.onLocationChange(this.locationFilter, this.customerType, this.usageHT, zipCode, city, street, houseNumber, addressID)];
                    case 1:
                        locationInfo = _a.sent();
                        if (locationInfo) {
                            this.locationInfo = locationInfo;
                            this.netzbetreiber = locationInfo.netzbetreiber;
                            this.grundversorger = locationInfo.grundversorger;
                            if (this.extern_ort && this.locationInfo.city)
                                this.city = this.extern_ort;
                            if (this.extern_strasse && this.locationInfo.street)
                                this.street = this.locationInfo.street;
                            if (this.extern_hausnummer && this.locationInfo.houseNumber)
                                this.houseNumber = this.locationInfo.houseNumber;
                        }
                        if (this.extern_strasse && this.locationInfo.street && this.extern_hausnummer && this.locationInfo.houseNumber && this.isLocationUnique && this.tariffCalcTypeId === '7')
                            this.onSubmitForm();
                        this.isLocationLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    TariffCalculatorInput.prototype.getAPIKey = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        apikey = this.$q.cookies.get('adserv');
                        if (!!apikey) return [3 /*break*/, 2];
                        return [4 /*yield*/, createAPIKey()];
                    case 1:
                        apikey = _a.sent();
                        this.$q.cookies.set('adserv', apikey, { expires: '4m' });
                        _a.label = 2;
                    case 2: return [2 /*return*/, apikey];
                }
            });
        });
    };
    // Emit
    TariffCalculatorInput.prototype.onSubmitForm = function () {
        return this.formValue;
    };
    TariffCalculatorInput.prototype.showContactForm = function () { };
    TariffCalculatorInput.prototype.showCancelationForm = function () { };
    // other methods
    TariffCalculatorInput.prototype.handleClearLocation = function () {
        this.locationInfo = { zipCode: '', unique: false };
        this.zipCode = '';
        this.city = '';
        this.street = null;
    };
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], TariffCalculatorInput.prototype, "styles", void 0);
    __decorate([
        Prop({ default: function () { return []; } })
    ], TariffCalculatorInput.prototype, "usageOptions", void 0);
    __decorate([
        Prop({ default: function () { return ({ usageHT: 3200, usageHTGas: 20000 }); } })
    ], TariffCalculatorInput.prototype, "initialValue", void 0);
    __decorate([
        Prop({ required: false, default: '1' })
    ], TariffCalculatorInput.prototype, "tariffCalculatorTypeId", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "versorgerIDs", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "bonus", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "markenIDs", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "tarifRootIDs", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "tarifRootIDsMindestfilter", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "zaehlerart", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "baiextendedWhiteList", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "stichtag", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "tarifcode", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "handlingTarife", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "plz", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "extern_ort", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "extern_strasse", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "extern_hausnummer", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "extern_AdressID", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "oeko", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "kundenart", void 0);
    __decorate([
        Prop({ required: false, default: false })
    ], TariffCalculatorInput.prototype, "teaser", void 0);
    __decorate([
        Prop({ required: false, default: false })
    ], TariffCalculatorInput.prototype, "loadAutomaticResult", void 0);
    __decorate([
        Prop({ required: false, default: '' })
    ], TariffCalculatorInput.prototype, "authToken", void 0);
    __decorate([
        Prop({ required: false, default: '' })
    ], TariffCalculatorInput.prototype, "authTokenRefresh", void 0);
    __decorate([
        Prop({ required: false, default: '' })
    ], TariffCalculatorInput.prototype, "authTokenUrl", void 0);
    __decorate([
        Prop({ required: false })
    ], TariffCalculatorInput.prototype, "locationFilter", void 0);
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], TariffCalculatorInput.prototype, "customLabels", void 0);
    __decorate([
        Prop({ default: function () { return ({}); }, type: Function })
    ], TariffCalculatorInput.prototype, "onLocationChange", void 0);
    __decorate([
        PropSync('context', { type: String })
    ], TariffCalculatorInput.prototype, "syncCtx", void 0);
    __decorate([
        PropSync('calculatorTypeId', { type: String })
    ], TariffCalculatorInput.prototype, "syncCalculatorTypeId", void 0);
    __decorate([
        PropSync('calculatorIDsConfig', { default: function () { } })
    ], TariffCalculatorInput.prototype, "syncCalculatorIDsConfig", void 0);
    __decorate([
        Watch('zipCode', { immediate: true })
    ], TariffCalculatorInput.prototype, "onZipCodeChange", null);
    __decorate([
        Watch('city')
    ], TariffCalculatorInput.prototype, "onCityChange", null);
    __decorate([
        Watch('street')
    ], TariffCalculatorInput.prototype, "onStreetChange", null);
    __decorate([
        Watch('houseNumber')
    ], TariffCalculatorInput.prototype, "onHouseNumberChange", null);
    __decorate([
        Emit('submit-form')
    ], TariffCalculatorInput.prototype, "onSubmitForm", null);
    __decorate([
        Emit()
    ], TariffCalculatorInput.prototype, "showContactForm", null);
    __decorate([
        Emit()
    ], TariffCalculatorInput.prototype, "showCancelationForm", null);
    TariffCalculatorInput = __decorate([
        Component({
            components: {
                QSelect: QSelect,
                QBtn: QBtn,
                QBtnGroup: QBtnGroup,
                QCard: QCard,
                QCardSection: QCardSection,
                QForm: QForm,
                QRadio: QRadio,
                QSeparator: QSeparator,
                QToggle: QToggle,
                QIcon: QIcon,
                QItemLabel: QItemLabel,
                QItemSection: QItemSection,
                QItem: QItem,
                QTooltip: QTooltip,
                TcAddress: TcAddress,
                TcUsage: TcUsage,
                Tooltip: Tooltip
            }
        })
    ], TariffCalculatorInput);
    return TariffCalculatorInput;
}(Vue));
export default TariffCalculatorInput;
