import { __assign, __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { QBtn, QCard, QCardSection, QExpansionItem, QForm, QIcon, QInput, QItem, QItemLabel, QItemSection, QList, QMarkupTable, QRadio, QSeparator, QSlideTransition, QToggle, QTooltip } from 'quasar';
import LoadingWrapper from '../../components/LoadingWrapper.vue';
import Tooltip from '../../components/tooltip/Tooltip.vue';
import { formatMoney } from '../../helpers';
import store from '../../store';
import { createAPIKey } from '../../utils/serviceProxy';
import useTariffWebService from '../../utils/tarif';
import TcUsage from '../TariffCalculatorInput/TcUsage.vue';
import TariffComparisonSelect from '../TariffComparisonSelect';
import labels from './labels';
var TcrResultObject = /** @class */ (function (_super) {
    __extends(TcrResultObject, _super);
    function TcrResultObject() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.kundenart = 0;
        _this.comparisonLabel = '';
        _this.tariffCardMap = new Map();
        return _this;
    }
    TcrResultObject.prototype.created = function () {
        var _a, _b, _c;
        this.compareTariff = (_a = this.selectCompareTariff()) !== null && _a !== void 0 ? _a : undefined;
        this.comparisonLabel = this.labels.savingTariff.concat(" " + ((_b = this.compareTariff) === null || _b === void 0 ? void 0 : _b.versorgerName) + " (" + ((_c = this.compareTariff) === null || _c === void 0 ? void 0 : _c.tarifName) + ")");
        // this.handleBruttoNetto();
    };
    Object.defineProperty(TcrResultObject.prototype, "labels", {
        // Computed
        get: function () {
            return __assign(__assign({}, labels), this.customLabels);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TcrResultObject.prototype, "countTRCard", {
        get: function () {
            return this.tariffCardMap.size;
        },
        enumerable: false,
        configurable: true
    });
    TcrResultObject.prototype.watchCompareTariff = function () {
        var _a, _b, _c;
        this.compareTariff = (_a = this.selectCompareTariff()) !== null && _a !== void 0 ? _a : undefined;
        this.comparisonLabel = this.labels.savingTariff.concat(" " + ((_b = this.compareTariff) === null || _b === void 0 ? void 0 : _b.versorgerName) + " (" + ((_c = this.compareTariff) === null || _c === void 0 ? void 0 : _c.tarifName) + ")");
    };
    Object.defineProperty(TcrResultObject.prototype, "hideComparisonSelect", {
        get: function () {
            return store.states.hideComparisonSelect;
        },
        enumerable: false,
        configurable: true
    });
    // handleBruttoNetto() {
    //   switch (this.inputData.customerType) {
    //     case 0:
    //       this.inputData.anzeigeNettoBrutto = 1;
    //       break;
    //     case 1:
    //       this.inputData.anzeigeNettoBrutto = 0;
    //       break;
    //     default:
    //       this.inputData.anzeigeNettoBrutto = 1;
    //       break;
    //   }
    //   this.anzeigeBruttoNetto = this.inputData.anzeigeNettoBrutto;
    // }
    TcrResultObject.prototype.loadTariffCard = function (tarifRootID, variantenID) {
        return __awaiter(this, void 0, void 0, function () {
            var apikey, getTariffCart, trCard;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _a.sent();
                        getTariffCart = useTariffWebService({ apikey: apikey }, this.context).getTariffCart;
                        if (!(tarifRootID > 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, getTariffCart(this.inputData, tarifRootID, variantenID)];
                    case 2:
                        trCard = _a.sent();
                        if (trCard)
                            this.tariffCardMap.set(tarifRootID
                                .toString()
                                .concat('#')
                                .concat(variantenID), trCard.tarifkarte[0]);
                        this.syncTariffCardCount = this.tariffCardMap.size;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    TcrResultObject.prototype.tariffCard = function (item) {
        return this.tariffCardMap.get(item.tarifRootID
            .toString()
            .concat('#')
            .concat(item.variantenID));
    };
    TcrResultObject.prototype.tariffCardPriceList = function (item) {
        var _a, _b, _c;
        if ((_a = this.tariffCard(item)) === null || _a === void 0 ? void 0 : _a.zeitZone)
            return (_b = this.tariffCard(item)) === null || _b === void 0 ? void 0 : _b.zeitZone[0].preisStaffeln;
        return (_c = this.tariffCard(item)) === null || _c === void 0 ? void 0 : _c.preisStaffel;
    };
    TcrResultObject.prototype.getAPIKey = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        apikey = this.$q.cookies.get('adserv');
                        if (!!apikey) return [3 /*break*/, 2];
                        return [4 /*yield*/, createAPIKey()];
                    case 1:
                        apikey = _a.sent();
                        this.$q.cookies.set('adserv', apikey, { expires: '4m' });
                        _a.label = 2;
                    case 2: return [2 /*return*/, apikey];
                }
            });
        });
    };
    TcrResultObject.prototype.changeCompareTariff = function () { };
    TcrResultObject.prototype.selectCompareTariff = function () {
        var _a, _b;
        if (!this.syncResList)
            return undefined;
        return this.choseProduct((_b = (_a = this.syncResList) === null || _a === void 0 ? void 0 : _a.findIndex) === null || _b === void 0 ? void 0 : _b.call(_a, function (f) { return f.isVergleichsTarif; }));
    };
    TcrResultObject.prototype.priceguaranteeIndex = function (item) {
        if (item.informationen) {
            return item === null || item === void 0 ? void 0 : item.informationen.findIndex(function (s) { return s.bedingungID === 101; });
        }
        return -1;
    };
    TcrResultObject.prototype.formatDate = function (datum) {
        var formatDate;
        var month = null;
        var day = null;
        if (datum) {
            if (datum === null || datum === void 0 ? void 0 : datum.dayOfMonth) {
                if (datum.dayOfMonth.toString().length < 2) {
                    day = "0" + datum.dayOfMonth;
                }
                else {
                    day = "" + datum.dayOfMonth;
                }
            }
            if (datum === null || datum === void 0 ? void 0 : datum.monthValue) {
                if (datum.monthValue.toString().length < 2) {
                    month = "0" + datum.monthValue;
                }
                else {
                    month = "" + datum.monthValue;
                }
            }
            var isNotValidFormat = ((datum === null || datum === void 0 ? void 0 : datum.year) + "-" + month + "-" + day).includes('undefined');
            if (datum && isNotValidFormat) {
                formatDate = datum;
            }
            else {
                formatDate = (datum === null || datum === void 0 ? void 0 : datum.year) + "-" + month + "-" + day;
            }
        }
        else
            return '';
        return formatDate;
    };
    TcrResultObject.prototype.getGrundpreisBN = function (tarif) {
        switch (this.anzeigeBruttoNetto) {
            case 0:
                return tarif.grundpreisNetto;
            case 1:
                return tarif.grundpreisBrutto;
            default:
                return tarif.grundpreisBrutto;
        }
    };
    TcrResultObject.prototype.getErsparnisBN = function (tarif) {
        switch (this.anzeigeBruttoNetto) {
            case 0:
                return tarif.ersparnisNetto;
            case 1:
                return tarif.ersparnisBrutto;
            default:
                return tarif.ersparnisBrutto;
        }
    };
    TcrResultObject.prototype.getArbeitsPreisStaffel = function (ps) {
        switch (this.anzeigeBruttoNetto) {
            case 0:
                return ps.arbeitspreisNetto;
            case 1:
                return ps.arbeitspreisBrutto;
            default:
                return ps.arbeitspreisBrutto;
        }
    };
    TcrResultObject.prototype.getGrundpreisStaffel = function (ps) {
        switch (this.anzeigeBruttoNetto) {
            case 0:
                return ps.grundpreisNetto;
            case 1:
                return ps.grundpreisBrutto;
            default:
                return ps.grundpreisBrutto;
        }
    };
    TcrResultObject.prototype.getGesamtpreisBN = function (tarif) {
        switch (this.anzeigeBruttoNetto) {
            case 0:
                return tarif.gesamtpreisNetto;
            case 1:
                return tarif.gesamtpreisBrutto;
            default:
                return tarif.gesamtpreisBrutto;
        }
    };
    TcrResultObject.prototype.getGesamtpreisOhneBoniBN = function (tarif) {
        switch (this.anzeigeBruttoNetto) {
            case 0:
                return tarif.gesamtpreisOhneBoniNetto;
            case 1:
                return tarif.gesamtpreisOhneBoniBrutto;
            default:
                return tarif.gesamtpreisOhneBoniBrutto;
        }
    };
    TcrResultObject.prototype.getArbeitspreisHTCtKWhBN = function (tarif) {
        switch (this.anzeigeBruttoNetto) {
            case 0:
                return tarif.arbeitspreisHTCtKWhNetto;
            case 1:
                return tarif.arbeitspreisHTCtKWhBrutto;
            default:
                return tarif.arbeitspreisHTCtKWhBrutto;
        }
    };
    TcrResultObject.prototype.getArbeitspreisCtKWhBN = function (tarif) {
        switch (this.anzeigeBruttoNetto) {
            case 0:
                return tarif.arbeitspreisCtKWhNetto;
            case 1:
                return tarif.arbeitspreisCtKWhBrutto;
            default:
                return tarif.arbeitspreisCtKWhBrutto;
        }
    };
    TcrResultObject.prototype.getArbeitspreisNTCtKWhBN = function (tarif) {
        switch (this.anzeigeBruttoNetto) {
            case 0:
                return tarif.arbeitspreisNTCtKWhNetto;
            case 1:
                return tarif.arbeitspreisNTCtKWhBrutto;
            default:
                return tarif.arbeitspreisNTCtKWhBrutto;
        }
    };
    TcrResultObject.prototype.getCalcWertBN = function (boni) {
        switch (this.anzeigeBruttoNetto) {
            case 0:
                return boni.calcWertNetto;
            case 1:
                return boni.calcWertBrutto;
            default:
                return boni.calcWertBrutto;
        }
    };
    TcrResultObject.prototype.formatValue = function (value) {
        return formatMoney(value);
    };
    TcrResultObject.prototype.formatNumber = function (number) {
        return number.toFixed(2).replace('.', ',');
    };
    TcrResultObject.prototype.formatIntNumber = function (number) {
        var formatter = new Intl.NumberFormat('de-DE', {});
        return formatter.format(number);
    };
    TcrResultObject.prototype.getMonthlyPrice = function (value) {
        return this.formatNumber(value / 11);
    };
    TcrResultObject.prototype.getChooseSelectName = function (index) {
        return "SelectTariff_" + index;
    };
    TcrResultObject.prototype.getChooseClassName = function (result) {
        return "TR-Result_" + result.tarifRootID;
    };
    TcrResultObject.prototype.calculatePaymentElectric = function (item) {
        if (item.netzEntgeltBerechnung) {
            return this.formatNumber(item.netzEntgeltBerechnung.entgeltFuerEinbauBetriebWartungMesstechnik);
        }
        return null;
    };
    TcrResultObject.prototype.isHandling = function (item) {
        var isHandling = false;
        if (item.dynamischeAngaben) {
            item.dynamischeAngaben.forEach(function (f) {
                if (f.stringObj) {
                    f.stringObj.forEach(function (t) {
                        if (t.name === 'handling' && t.wert === 'true')
                            isHandling = true;
                    });
                }
            });
        }
        return isHandling;
    };
    TcrResultObject.prototype.abschlag = function (item) {
        var _this = this;
        var abschlag = 0;
        if (item.dynamischeAngaben) {
            item.dynamischeAngaben.forEach(function (f) {
                if (f.doubleObj) {
                    f.doubleObj.forEach(function (t) {
                        switch (_this.anzeigeBruttoNetto) {
                            case 0:
                                if (t.name === 'gesamtpreisMonatOhneBoniNetto')
                                    abschlag = t.wert;
                                break;
                            case 1:
                                if (t.name === 'gesamtpreisMonatOhneBoniBrutto')
                                    abschlag = t.wert;
                                break;
                            default:
                                if (t.name === 'gesamtpreisMonatOhneBoniBrutto')
                                    abschlag = t.wert;
                                break;
                        }
                    });
                }
            });
        }
        return abschlag.toFixed(0).replace('.', ',');
    };
    TcrResultObject.prototype.choseProduct = function (index) {
        var _this = this;
        if (index < 0)
            return undefined;
        var _a = this.syncResList[index], grundpreisBrutto = _a.grundpreisBrutto, gesamtpreisBrutto = _a.gesamtpreisBrutto, arbeitspreisHTCtKWhBrutto = _a.arbeitspreisHTCtKWhBrutto, arbeitspreisBrutto = _a.arbeitspreisBrutto, arbeitspreisHTCtKWhNetto = _a.arbeitspreisHTCtKWhNetto, arbeitspreisNetto = _a.arbeitspreisNetto, arbeitspreisNTCtKWhBrutto = _a.arbeitspreisNTCtKWhBrutto, arbeitspreisNTCtKWhNetto = _a.arbeitspreisNTCtKWhNetto, ersparnisBrutto = _a.ersparnisBrutto, ersparnisNetto = _a.ersparnisNetto, gesamtpreisNetto = _a.gesamtpreisNetto, gesamtpreisOhneBoniBrutto = _a.gesamtpreisOhneBoniBrutto, gesamtpreisOhneBoniNetto = _a.gesamtpreisOhneBoniNetto, grundpreisNetto = _a.grundpreisNetto, isGrundversorgungsTarif = _a.isGrundversorgungsTarif, isOekostromTarif = _a.isOekostromTarif, isOekogasTarif = _a.isOekogasTarif, isPaketTarif = _a.isPaketTarif, isVergleichsTarif = _a.isVergleichsTarif, isVorkasseTarif = _a.isVorkasseTarif, kuendigungsfrist = _a.kuendigungsfrist, kuendigungsfristID = _a.kuendigungsfristID, markenID = _a.markenID, tarifCode = _a.tarifCode, tarifID = _a.tarifID, tarifInfo = _a.tarifInfo, tarifName = _a.tarifName, tarifRootID = _a.tarifRootID, variantenID = _a.variantenID, versorgerID = _a.versorgerID, versorgerName = _a.versorgerName, vertragslaufzeit = _a.vertragslaufzeit, vertragslaufzeitID = _a.vertragslaufzeitID, vertragsverlaengerung = _a.vertragsverlaengerung, vertragsverlaengerungID = _a.vertragsverlaengerungID, gueltigVon = _a.gueltigVon, informationen = _a.informationen, dynamischeAngaben = _a.dynamischeAngaben, arbeitspreisCtKWhBrutto = _a.arbeitspreisCtKWhBrutto, arbeitspreisCtKWhNetto = _a.arbeitspreisCtKWhNetto, boni = _a.boni, aufschlaege = _a.aufschlaege;
        var informationenTemp = [];
        var dynamischeAngabenTemp = [];
        var boniTemp = [];
        var aufschlaegeTemp = [];
        dynamischeAngaben === null || dynamischeAngaben === void 0 ? void 0 : dynamischeAngaben.forEach(function (d) {
            var temp = __assign({}, d);
            dynamischeAngabenTemp.push(temp);
        });
        aufschlaege === null || aufschlaege === void 0 ? void 0 : aufschlaege.forEach(function (auf) {
            var temp = __assign({}, auf);
            aufschlaegeTemp.push(temp);
        });
        boni === null || boni === void 0 ? void 0 : boni.forEach(function (b) {
            var temp = __assign({}, b);
            boniTemp.push(temp);
        });
        informationen === null || informationen === void 0 ? void 0 : informationen.forEach(function (i) {
            var temp = __assign(__assign({}, i), { preisgarantieBis: _this.formatDate(i.preisgarantieBis), gueltigVon: _this.formatDate(i.gueltigVon), gueltigBis: _this.formatDate(i.gueltigBis) });
            informationenTemp.push(temp);
        });
        return {
            grundpreisBrutto: grundpreisBrutto,
            gesamtpreisBrutto: gesamtpreisBrutto,
            arbeitspreisBrutto: arbeitspreisBrutto,
            arbeitspreisHTCtKWhBrutto: arbeitspreisHTCtKWhBrutto,
            arbeitspreisNetto: arbeitspreisNetto,
            arbeitspreisNTCtKWhBrutto: arbeitspreisNTCtKWhBrutto,
            arbeitspreisNTCtKWhNetto: arbeitspreisNTCtKWhNetto,
            ersparnisBrutto: ersparnisBrutto,
            ersparnisNetto: ersparnisNetto,
            arbeitspreisHTCtKWhNetto: arbeitspreisHTCtKWhNetto,
            gesamtpreisNetto: gesamtpreisNetto,
            gesamtpreisOhneBoniBrutto: gesamtpreisOhneBoniBrutto,
            gesamtpreisOhneBoniNetto: gesamtpreisOhneBoniNetto,
            grundpreisNetto: grundpreisNetto,
            gueltigVon: this.formatDate(gueltigVon),
            isGrundversorgungsTarif: isGrundversorgungsTarif,
            isOekostromTarif: isOekostromTarif,
            isOekogasTarif: isOekogasTarif,
            isPaketTarif: isPaketTarif,
            isVergleichsTarif: isVergleichsTarif,
            isVorkasseTarif: isVorkasseTarif,
            kuendigungsfrist: kuendigungsfrist,
            kuendigungsfristID: kuendigungsfristID,
            markenID: markenID,
            tarifCode: tarifCode !== null && tarifCode !== void 0 ? tarifCode : '',
            tarifID: tarifID,
            tarifInfo: tarifInfo !== null && tarifInfo !== void 0 ? tarifInfo : '',
            tarifName: tarifName,
            tarifRootID: tarifRootID,
            variantenID: variantenID !== null && variantenID !== void 0 ? variantenID : '',
            versorgerID: versorgerID,
            versorgerName: versorgerName,
            vertragslaufzeit: vertragslaufzeit,
            vertragslaufzeitID: vertragslaufzeitID,
            vertragsverlaengerung: vertragsverlaengerung,
            vertragsverlaengerungID: vertragsverlaengerungID,
            informationen: informationenTemp,
            dynamischeAngaben: dynamischeAngabenTemp,
            arbeitspreisCtKWhBrutto: arbeitspreisCtKWhBrutto,
            arbeitspreisCtKWhNetto: arbeitspreisCtKWhNetto,
            boni: boniTemp,
            aufschlaege: aufschlaegeTemp,
            preisStaffeln: [],
            rootId: 0,
            productName: '',
            productTypeId: 0,
            productTypeName: '',
            companyId: 0,
            companyName: '',
            providerId: 0,
            providerName: '',
            productCode: '0',
            validFrom: undefined,
            validTo: undefined,
            customerGroupPrivate: false,
            customerGroupCommercial: false,
            customerGroupYoungPeople: false,
            gridConnectionType: 0,
            connectionTypes: [],
            contractPeriodType: 0,
            contractPeriodMonths: 0,
            contractPeriodDate: undefined,
            noticePeriodMonths: 0,
            contractExtensionType: 0,
            contractExtensionMonths: 0,
            contractExtensionDate: undefined,
            productDescription: '',
            landlineDataVolumeFlat: false,
            landlineDataVolumeLimit: 0,
            landlineInternetDownloadSpeed: 0,
            landlineInternetDownloadSpeedMinimal: 0,
            landlineInternetDownloadSpeedNormal: 0,
            landlineInternetUploadSpeed: 0,
            landlineInternetUploadSpeedMinimal: 0,
            landlineInternetUploadSpeedNormal: 0,
            landlineTelephone: false,
            landlineTelephoneNumbers: 0,
            landlineTelephoneFlatLandline: false,
            landlineTelephoneCostMinutes: 0,
            installCost: 0,
            setupCost: 0,
            basicPrice: 0,
            shippingCosts: 0,
            shippingCost: 0,
            lastUpdateDate: undefined,
            lastUpdateUserId: 0,
            version: 0
        };
    };
    TcrResultObject.prototype.selectProduct = function () { };
    TcrResultObject.prototype.goTo = function () { };
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], TcrResultObject.prototype, "customLabels", void 0);
    __decorate([
        Prop({ default: false })
    ], TcrResultObject.prototype, "isLoading", void 0);
    __decorate([
        PropSync('resultList', { default: function () { return []; } })
    ], TcrResultObject.prototype, "syncResList", void 0);
    __decorate([
        PropSync('tariffCardCount', { type: Number, required: false })
    ], TcrResultObject.prototype, "syncTariffCardCount", void 0);
    __decorate([
        Prop()
    ], TcrResultObject.prototype, "context", void 0);
    __decorate([
        Prop({ required: true })
    ], TcrResultObject.prototype, "inputData", void 0);
    __decorate([
        Prop({ required: false, default: 0 })
    ], TcrResultObject.prototype, "anzeigeBruttoNetto", void 0);
    __decorate([
        Watch('syncResList')
    ], TcrResultObject.prototype, "watchCompareTariff", null);
    __decorate([
        Emit()
    ], TcrResultObject.prototype, "changeCompareTariff", null);
    __decorate([
        Emit()
    ], TcrResultObject.prototype, "selectProduct", null);
    __decorate([
        Emit()
    ], TcrResultObject.prototype, "goTo", null);
    TcrResultObject = __decorate([
        Component({
            components: {
                LoadingWrapper: LoadingWrapper,
                QBtn: QBtn,
                QCard: QCard,
                QIcon: QIcon,
                QItem: QItem,
                QItemLabel: QItemLabel,
                QSeparator: QSeparator,
                QTooltip: QTooltip,
                QList: QList,
                QItemSection: QItemSection,
                QExpansionItem: QExpansionItem,
                QForm: QForm,
                QInput: QInput,
                QCardSection: QCardSection,
                QToggle: QToggle,
                QSlideTransition: QSlideTransition,
                TcUsage: TcUsage,
                Tooltip: Tooltip,
                QRadio: QRadio,
                QMarkupTable: QMarkupTable,
                TariffComparisonSelect: TariffComparisonSelect
            }
        })
    ], TcrResultObject);
    return TcrResultObject;
}(Vue));
export default TcrResultObject;
