import { __assign, __awaiter, __decorate, __extends, __generator } from "tslib";
import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { ClosePopup, QBtn, QBtnGroup, QBtnToggle, QCard, QCardSection, QDate, QExpansionItem, QForm, QIcon, QInput, QItem, QItemLabel, QItemSection, QList, QOptionGroup, QPopupProxy, QSelect, QSeparator, QSpinner, QTab, QTabPanel, QTabPanels, QTabs, QTooltip } from 'quasar';
import store from '../../store';
import { createAPIKey } from '../../utils/serviceProxy';
import useTariffWebService from '../../utils/tarif';
var TariffComparisonSelect = /** @class */ (function (_super) {
    __extends(TariffComparisonSelect, _super);
    function TariffComparisonSelect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.versorgerListe = [];
        _this.tariffList = [];
        _this.versorgerid = null;
        _this.tariffTab = 'compareTariff';
        return _this;
    }
    Object.defineProperty(TariffComparisonSelect.prototype, "isNetto", {
        get: function () {
            this.vergleichAPBruttoSynced = null;
            this.vergleichGrundpreisJahrBruttoSynced = null;
            return this.showBruttoOrNetto === 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffComparisonSelect.prototype, "isBrutto", {
        get: function () {
            this.vergleichAPNettoSynced = null;
            this.vergleichGrundpreisJahrNettoSynced = null;
            return this.showBruttoOrNetto === 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffComparisonSelect.prototype, "hideComparisonSelect", {
        get: function () {
            return store.states.hideComparisonSelect;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffComparisonSelect.prototype, "labelAP", {
        get: function () {
            return this.isBrutto ? 'Arbeitspreis Brutto (Ct / kWh)' : 'Arbeitspreis Netto (Ct / kWh)';
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TariffComparisonSelect.prototype, "labelGP", {
        get: function () {
            return this.isNetto ? 'Grundpreis Netto (€ / Jahr)' : 'Grundpreis Brutto (€ / Jahr)';
        },
        enumerable: false,
        configurable: true
    });
    TariffComparisonSelect.prototype.mounted = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey, getCompareSupplierList, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _b.sent();
                        getCompareSupplierList = useTariffWebService({ apikey: apikey }, this.context).getCompareSupplierList;
                        _a = this;
                        return [4 /*yield*/, getCompareSupplierList(this.inputData)];
                    case 2:
                        _a.versorgerListe = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TariffComparisonSelect.prototype.onChangeAPBrutto = function (val) {
        this.vergleichAPBruttoSynced = this.checkIsNumber(val);
        if (!this.vergleichAPBruttoSynced)
            return null;
        return this.vergleichAPBruttoSynced;
    };
    TariffComparisonSelect.prototype.onChangeGPBrutto = function (val) {
        this.vergleichGrundpreisJahrBruttoSynced = this.checkIsNumber(val);
        if (!this.vergleichGrundpreisJahrBruttoSynced)
            return null;
        return this.vergleichGrundpreisJahrBruttoSynced;
    };
    TariffComparisonSelect.prototype.onChangeAPNetto = function (val) {
        this.vergleichAPNettoSynced = this.checkIsNumber(val);
        if (!this.vergleichAPNettoSynced)
            return null;
        return this.vergleichAPNettoSynced;
    };
    TariffComparisonSelect.prototype.onChangeGPNetto = function (val) {
        this.vergleichGrundpreisJahrNettoSynced = this.checkIsNumber(val);
        if (!this.vergleichGrundpreisJahrNettoSynced)
            return null;
        return this.vergleichGrundpreisJahrNettoSynced;
    };
    TariffComparisonSelect.prototype.checkIsNumber = function (val) {
        var convertToNumber = Number(val);
        if (Number.isNaN(convertToNumber)) {
            return;
        }
        // eslint-disable-next-line consistent-return
        return convertToNumber;
    };
    TariffComparisonSelect.prototype.loadTariffList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey, getCompareTariffList, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.compareTariffIDSynced = null;
                        return [4 /*yield*/, this.getAPIKey()];
                    case 1:
                        apikey = _b.sent();
                        getCompareTariffList = useTariffWebService({ apikey: apikey }, this.context).getCompareTariffList;
                        _a = this;
                        return [4 /*yield*/, getCompareTariffList(this.inputData, this.versorgerid || 0)];
                    case 2:
                        _a.tariffList = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TariffComparisonSelect.prototype.onChangeTab = function () {
        this.versorgerid = null;
        this.vergleichAPBruttoSynced = null;
        this.vergleichAPNettoSynced = null;
        this.vergleichGrundpreisJahrNettoSynced = null;
        this.vergleichGrundpreisJahrBruttoSynced = null;
    };
    TariffComparisonSelect.prototype.getAPIKey = function () {
        return __awaiter(this, void 0, void 0, function () {
            var apikey;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        apikey = this.$q.cookies.get('adserv');
                        if (!!apikey) return [3 /*break*/, 2];
                        return [4 /*yield*/, createAPIKey()];
                    case 1:
                        apikey = _a.sent();
                        this.$q.cookies.set('adserv', apikey, { expires: '4m' });
                        _a.label = 2;
                    case 2: return [2 /*return*/, apikey];
                }
            });
        });
    };
    TariffComparisonSelect.prototype.data = function () {
        return {
            model: 'one',
            secondModel: 'one',
            thirdModel: 'one'
        };
    };
    Object.defineProperty(TariffComparisonSelect.prototype, "labels", {
        get: function () {
            return __assign({}, this.customLabels);
        },
        enumerable: false,
        configurable: true
    });
    TariffComparisonSelect.prototype.changeCompareTariff = function () { };
    __decorate([
        Prop({ required: true })
    ], TariffComparisonSelect.prototype, "inputData", void 0);
    __decorate([
        Prop({ default: function () { return ({}); } })
    ], TariffComparisonSelect.prototype, "customLabels", void 0);
    __decorate([
        Prop()
    ], TariffComparisonSelect.prototype, "context", void 0);
    __decorate([
        Prop()
    ], TariffComparisonSelect.prototype, "compareTariff", void 0);
    __decorate([
        Prop()
    ], TariffComparisonSelect.prototype, "comparisonLabel", void 0);
    __decorate([
        Prop()
    ], TariffComparisonSelect.prototype, "showBruttoOrNetto", void 0);
    __decorate([
        PropSync('compareTariffID')
    ], TariffComparisonSelect.prototype, "compareTariffIDSynced", void 0);
    __decorate([
        PropSync('vergleichAPBrutto')
    ], TariffComparisonSelect.prototype, "vergleichAPBruttoSynced", void 0);
    __decorate([
        PropSync('vergleichAPNetto')
    ], TariffComparisonSelect.prototype, "vergleichAPNettoSynced", void 0);
    __decorate([
        PropSync('vergleichGrundpreisJahrBrutto')
    ], TariffComparisonSelect.prototype, "vergleichGrundpreisJahrBruttoSynced", void 0);
    __decorate([
        PropSync('vergleichGrundpreisJahrNetto')
    ], TariffComparisonSelect.prototype, "vergleichGrundpreisJahrNettoSynced", void 0);
    __decorate([
        PropSync('vergleichGrundpreisMonatBrutto')
    ], TariffComparisonSelect.prototype, "vergleichGrundpreisMonatBruttoSynced", void 0);
    __decorate([
        PropSync('vergleichGrundpreisMonatNetto')
    ], TariffComparisonSelect.prototype, "vergleichGrundpreisMonatNettoSynced", void 0);
    __decorate([
        PropSync('vergleichGesamtpreisJahrBrutto')
    ], TariffComparisonSelect.prototype, "vergleichGesamtpreisJahrBruttoSynced", void 0);
    __decorate([
        PropSync('vergleichGesamtpreisJahrNetto')
    ], TariffComparisonSelect.prototype, "vergleichGesamtpreisJahrNettoSynced", void 0);
    __decorate([
        PropSync('vergleichGesamtpreisMonatBrutto')
    ], TariffComparisonSelect.prototype, "vergleichGesamtpreisMonatBruttoSynced", void 0);
    __decorate([
        PropSync('vergleichGesamtpreisMonatNetto')
    ], TariffComparisonSelect.prototype, "vergleichGesamtpreisMonatNettoSynced", void 0);
    __decorate([
        Watch('versorgerid')
    ], TariffComparisonSelect.prototype, "loadTariffList", null);
    __decorate([
        Watch('tariffTab')
    ], TariffComparisonSelect.prototype, "onChangeTab", null);
    __decorate([
        Emit()
    ], TariffComparisonSelect.prototype, "changeCompareTariff", null);
    TariffComparisonSelect = __decorate([
        Component({
            components: {
                QCard: QCard,
                QDate: QDate,
                QCardSection: QCardSection,
                QExpansionItem: QExpansionItem,
                QForm: QForm,
                QInput: QInput,
                QIcon: QIcon,
                QList: QList,
                QItem: QItem,
                QItemLabel: QItemLabel,
                QItemSection: QItemSection,
                QBtn: QBtn,
                QOptionGroup: QOptionGroup,
                QBtnGroup: QBtnGroup,
                QSelect: QSelect,
                QPopupProxy: QPopupProxy,
                QSpinner: QSpinner,
                QSeparator: QSeparator,
                QTooltip: QTooltip,
                QBtnToggle: QBtnToggle,
                QTab: QTab,
                QTabPanel: QTabPanel,
                QTabPanels: QTabPanels,
                QTabs: QTabs
            },
            directives: {
                ClosePopup: ClosePopup
            }
        })
    ], TariffComparisonSelect);
    return TariffComparisonSelect;
}(Vue));
export default TariffComparisonSelect;
