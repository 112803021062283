import Vue from 'vue';
var store = Vue.observable({
    states: {
        geeSkipButton: false,
        ownerSameAsCustomer: false,
        isContinueAsOffer: false,
        tabPanelName: null,
        hideComparisonSelect: false,
        clientWidth: 0,
        maxMultiSelect: 7,
        forms: {
            contactData: {
                email: '',
                firstName: '',
                lastName: '',
                birthday: '',
                phoneNumber: '',
                salutation: ''
            },
            addressData: {
                billingSameAsDelivery: false,
                city: '',
                houseNumber: '',
                street: '',
                zipCode: ''
            },
            preSupplierData: {
                currentMeterReading: 0,
                dateMoveIn: '',
                dateOfCancellation: '',
                desiredDate: '',
                maLoID: '',
                meterNumber: '',
                newMeterNumber: '',
                prevCustomerNum: '',
                prevSupplier: '',
                reasonToChange: '',
                startOfDelivery: '',
                terminatePrevSupplier: ''
            },
            bankData: {
                agreeSepa: false,
                bic: '',
                creditInstitution: '',
                firstName: '',
                iban: '',
                lastName: '',
                paymentMethod: '',
                remoteSignature: false
            }
        },
        defaultLabels: {
            acceptGeneralTncLabel: '',
            acceptCancellationPolicyLabel: '',
            acceptacceptPrivacyPolicyLabel: '',
            acceptProductDiscriptionabel: '',
            acceptCounsellingProtocollLabel: ''
        }
    }
});
export default store;
